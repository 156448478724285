/* eslint-disable prettier/prettier */
export const state = () => ({
  app_bar_title: '',
  skipped_conversations: [],
  messageCountFilterRange: [-1, -1],
  daysSinceLastMessageConversationFilter: [0, 365],
  daysSinceLastMessageCustomerFilter: [0, 365],
  operatorStatusFilter: ['unread', 'unanswered'],
  numberOfPokesFilter: [0, 10],
  paidUserStatusFilter: [],
});

export const mutations = {
  CHANGE_TITLE(state, title) {
    state.app_bar_title = title;
  },
  SKIP_CONVERSATION(state, conversationId) {
    state.skipped_conversations.push(conversationId);
  },
  REMOVE_ONE_SKIP_CONVERSATION(state, conversationId) {
    state.skipped_conversations.shift();
  },
  setMessageCountFilterRange(state, range) {
    state.messageCountFilterRange = range;
  },
  setDaysSinceLastMessageConversationFilter(state, range) {
    state.daysSinceLastMessageConversationFilter = range;
  },
  setDaysSinceLastMessageCustomerFilter(state, range) {
    state.daysSinceLastMessageCustomerFilter = range;
  },
  setOperatorStatusFilter(state, range) {
    state.operatorStatusFilter = range;
  },
  setNumberOfPokesFilter(state, range) {
    state.numberOfPokesFilter = range;
  },
  setPaidUserStatusFilter(state, range) {
    state.paidUserStatusFilter = range;
  },

};