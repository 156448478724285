export default function ({ $axios, app, redirect }) {
  $axios.onError((error) => {
    const code = parseInt(error.response?.status);
    if (code === 401) {
      if (
        error.request &&
        !error.request.responseURL.endsWith('user.php') &&
        !error.request.responseURL.endsWith('tfa.php')
      ) {
        if (app.$auth.loggedIn) {
          app.$auth
            .logout()
            .then((res) => {})
            .catch(() => {});
        }
        return Promise.resolve(false);
      }
    }
  });
}
