import Vue from 'vue';
import CoolLightBox from 'vue-cool-lightbox';
import contenteditable from 'vue-contenteditable';
import VueForceNextTick from 'vue-force-next-tick';

import VueSimpleContextMenu from 'vue-simple-context-menu';
Vue.component('VueSimpleContextMenu', VueSimpleContextMenu);

// import Vcalendar from 'v-calendar'

Vue.use(contenteditable);
CoolLightBox.methods.oldBeforeDestroy = CoolLightBox.beforeDestroy;
CoolLightBox.beforeDestroy = function () {
  this.oldBeforeDestroy();
  window.removeEventListener('keydown', this.eventListener);
  window.removeEventListener('click', this.showButtons);
  window.removeEventListener('resize', this.xPositionOnResize);
  window.removeEventListener('wheel', this.wheelEvent);
};
Vue.use(CoolLightBox);
Vue.use(VueForceNextTick);
