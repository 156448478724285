export default {
  FICTIONAL_PROFILE_FETISH: 'Fictional profile fetish: {name}',
  REQUEST_AWAY_TIME: 'Away from Chat time',
  REQUEST_AWAY_TIME_SUB: 'Get 2 minutes',
  REQUEST_AWAY_TIME_WHY:
    'Why do you need time away from this chat? All requests are reported to coaches.',
  REQUEST_AWAY_TIME_BTN: 'Get 2 minutes',
  '{count} Chats available': '(1) chat available | ({count}) chats available',
  fictional_text_warning:
    'User might be talking about fictional profiles. Please contact master.',
  chat_back_to: {
    index: 'Back to main panel',
    messages: 'Back to @:(chat_overview)',
    history: 'Back to @:(message_history)',
    message_reports: 'Back to @:(message_reports)',
    skiplog: 'back to @:(skiplog)',
  },
  SKIP_MESSAGE_REASON_TITLE: 'Enter reason for skip',
  SKIP_REASON: 'Skip Reason',
  SKIP_REASON_HINT: 'Explain why you need to skip this message',
  SKIP_MESSAGE_CANCEL: 'Back to conversation',
  SKIP_MESSAGE_CONTINUE: 'Skip message',
  'Report message': 'Report message',
  REPORT_MESSAGE_BTN: 'Send message report',
  REPORT_MESSAGE: 'Report message',
  REPORT_NOTE_HINT: "Explain what's wrong with this message",
  REPORT_REASON_LABEL: 'Reason',
  REPORT_NOTE_LABEL: 'Notes',
  chat_overview: 'Chat overview',
  skiplog: 'Skiplog',
  message_reports: 'Message reports',
  'Number of messages in conversation': 'Number of messages in conversation',
  'Filter conversations': 'Filter conversations',
  'No minimum': 'No minimum',
  'No maximum': 'No maximum',
  'All statuses': 'All statuses',
  'Operator status': 'Operator status',
  'Paid users': 'Paid users',
  'Free users': 'Free users',
  'Member type': 'Member type',
  'Number of pokes after last message': 'Number of pokes after last message',
  'All users': 'All users',
  Minimum: 'Minimum',
  Maximum: 'Maximum',
  operator_status: {
    unread: 'Unread',
    unanswered: 'Unanswered',
    ignored: 'Ignored',
    answered: 'Answered',
  },

  Length: 'Length',
  Message: 'Message',
  Answered: 'Answered',
  SKIP_DATE: 'Skipped',
  SENT_DATE: 'Sent',
  'Filter messages': 'Filter messages',
  'All message types': 'All message types',
  'All operators': 'All operators',
  'Message type': 'Message type',
  message_history: 'Message history',
  'load many': 'load many',
  SKIP_MESSAGE_WARNING_TITLE: 'Unsend message',
  SKIP_MESSAGE_WARNING:
    'Are you sure you want to continue without sending your message?',
  SKIP_MESSAGE_SEND: 'Send message now',
  SKIP_MESSAGE_DELETE: 'Delete message',
  Paused: 'Paused',
  Permissions: 'Permissions',
  operator_name: 'Name',
  open_conversations: 'Open conversations',
  no_open_conversations: 'No open conversations / waiting in queue',
  logged_in_operators: 'Chatting operators',
  language: 'Language',
  cnt_pokes: '# pokes',
  cnt_messages: '# messages',
  cnt_operators: '# operators',

  oldest_message: 'Oldest message',
  newest_message: 'Latest message',
  QUEUE_STATS: 'Queue statistics',
  SOMEONE_ELSE_ANSWERING: '{name} is answering this chat.',
  SAVE: 'Save',
  SET_ALERT: 'Set alert',
  AT_CUSTOMER_PROFILE: 'At customer profile',
  STOP_POKES: 'Stop pokes',
  IGNORE: 'Ignore chat',
  UNTIL_NEXT_MESSAGE: 'Until next message',
  IGNORE_CONVERSATION_WARNING:
    "User won't get a reply! Only use this in case of abuse and no one should answer this (for example minors)",
  stats: {
    total: 'Total all operators',
    message_type: 'Message type',
    count_messages: '# messages',
    count_messages_answered: '# messages with answer',
    count_messages_answered_percentage: 'Percentage with answer',
    types: {
      starter: 'Conversation starter',
      reply: 'First reply on customers message (or after photo)',
      extra_reply: 'Additional replies',
      poke: 'Poke',
      extra_poke: 'Additional poke',
      starter_photo: 'Conversation starter (photo)',
      reply_photo: 'First reply on customers message (photo)',
      extra_reply_photo: 'Additional replies (photo)',
      poke_photo: 'Poke (photo)',
      extra_poke_photo: 'Additional poke (photo)',
      starter_gift: 'Conversation starter (gift)',
      reply_gift: 'First reply on customers message (gift)',
      extra_reply_gift: 'Additional replies (gift)',
      poke_gift: 'Poke (gift)',
      extra_poke_gift: 'Additional poke (gift)',
    },
  },

  Stats: 'Stats',
  OTHER_CHAT_COUNT: '1 chat | {count} chats',
  FREE_MEMBER: 'Trial member',
  PREMIUM_MEMBER: 'Premium member',
  USER_CREDITS: '1 credit remaining | {count} credits remaining',
  LAST_MESSAGE: '1 message, {date} | {num_messages} messages, last {date}',
  MEMBER_SINCE: 'Member since {date}',
  MESSAGE_COUNT: '1 message | {count} messages',
  PHOTOMESSAGE_COUNT: '1 photo | {count} photos',
  INVISIBLE_PHOTOMESSAGE_COUNT: '1 invisible photo | {count} invisible photos',
  MESSAGE_SINCE: 'First message: {date}',
  today: 'Today',
  yesterday: 'Yesterday',
  TFA_LOGIN:
    'Enter the 6 digits from your 2FA app (Google Authenticater or Authy)',
  SEND_POKE: 'Send {name} a poke!',
  AGE_DISPLAY: '{age} yo',
  'Empty chat, send a message first.': 'Empty chat, send a message first.',
  'Load more messages': 'Load more messages',
  loading_messages: 'Messages are loading...',
  SEND_MESSAGE_PLACEHOLDER: 'Send message',
  SEND_BTN: 'Send',
  LOCKED_BY: 'Message locked by: {name}',
  CUSTOMER: 'CUSTOMER',
  PLAYER: 'PLAYER',
  'Open chat queue': 'Open chat queue',
  'Open poke queue': 'Open poke queue',
  'Poke Queue': 'Poke queue',
  Logout: 'Logout',
  'Waiting for new message': 'Waiting for new message',
  'Message Queue': 'Message Queue',
  LOGIN_BLOCKED: 'Too many failed attempts, please try again in 10 minutes.',
  LOGIN_FAILED: 'Login failed, try again',
  male: 'male',
  female: 'female',
  personal: 'Personal',
  birthday: 'Birthday',
  age: 'Age',
  city: 'City',
  sports: 'Sport',
  job: 'Job',
  likes: 'Likes',
  hobbies: 'Hobbies',
  children: 'Kids',
  pets: 'Pets',
  important: 'Important',
  piercings: 'Piercings',
  reason: 'Reason',
  other: 'Other',
  smokes: 'Smokes',
  tattoos: 'Tattoos',
  eye_color: 'Eye color',
  hair_color: 'Hair color',
  reletionship_status: 'Relationship',
  yes: 'Yes',
  no: 'No',
  blue: 'Blue',
  brown: 'Brown',
  green: 'Green',
  gray: 'Gray',
  hazel: 'Hazel',
  bald: 'Bald',
  black: 'Black',
  blond: 'Blond',
  'dark blond': 'Dark blond',
  'dark brown': 'Dark brown',
  red: 'Red',
  divorced: 'Divorced',
  'living together': 'Living together',
  married: 'Married',
  single: 'Single',
  unmarried: 'Unmarried',
  widowed: 'Widowed',
  sometimes: 'Sometimes',
  stopping: 'Trying to stop',
  hetero: 'Hetero',
  gay: 'Gay',
  lesbian: 'Lesbian',
  bi: 'Bi-sexual',
  length: 'Height',
  weight: 'Weight',
};
