export default {
  FICTIONAL_PROFILE_FETISH: 'Fictional profile fetish: {name}',
  '{count} Chats available': '(1) chat available | ({count}) chats available',
  fictional_text_warning:
    'Lo user potrebbe star parlando di profili fittizi. Ti preghiamo di contattare un coach.',
  chat_back_to: {
    index: 'Torna al pannello principale',
    messages: 'Torna a @:(chat_overview)',
    history: 'Torna a @:(message_history)',
    message_reports: 'Torna a @:(message_reports)',
    skiplog: 'torna a @:(skiplog)',
  },
  SKIP_MESSAGE_REASON_TITLE: 'Inserisci il motivo per cui vuoi passare',
  SKIP_REASON: 'Motivo del cambio',
  SKIP_REASON_HINT: 'Spiega perché vuoi saltare questo messaggio',
  SKIP_MESSAGE_CANCEL: 'Torna alla conversazione',
  SKIP_MESSAGE_CONTINUE: 'Salta messaggio',
  'Report message': 'Segnala messaggio',
  REPORT_MESSAGE_BTN: 'Invia segnalazione messaggio',
  REPORT_MESSAGE: 'Segnala messaggio',
  REPORT_NOTE_HINT: 'Spiega che cosa non va in questo messaggio',
  REPORT_REASON_LABEL: 'Motivo',
  REPORT_NOTE_LABEL: 'Note',
  chat_overview: 'Panoramica della chat',
  skiplog: 'Skiplog',
  message_reports: 'Reports messaggi',
  'Number of messages in conversation':
    'Numero dei messaggi nella conversazione',
  'Filter conversations': 'Filter conversations',
  'No minimum': 'No minimo',
  'No maximum': 'No massimo',
  'All statuses': 'Tutti gli status',
  'Operator status': 'Stato operatore',
  'Paid users': 'Utenti pagante',
  'Free users': 'Utenti Free',
  'Member type': 'Member type',
  'Number of pokes after last message':
    "Numero di pokes dopo l'ultimo messaggio",
  'All users': 'Tutti gli utenti',
  Minimum: 'Minimo',
  Maximum: 'Massimo',
  operator_status: {
    unread: 'Non letto',
    unanswered: 'Non risposto',
    ignored: 'Ignorato',
    answered: 'Risposto',
  },

  Length: 'Lunghezza',
  Message: 'Messaggio',
  Answered: 'Risposto',
  SKIP_DATE: 'Saltato',
  SENT_DATE: 'Inviato',
  'Filter messages': 'Filtra messaggi',
  'All message types': 'Tutti i tipi di messaggio',
  'All operators': 'Tutti gli operatori',
  'Message type': 'Tipo di messaggio',
  message_history: 'Cronologia messaggio',
  'load many': 'Carica di più',
  SKIP_MESSAGE_WARNING_TITLE: 'Annulla invia messaggio',
  SKIP_MESSAGE_WARNING:
    'Sei sicuro di voler continuare senza inviare il messaggio?',
  SKIP_MESSAGE_SEND: 'Invia messaggio adesso',
  SKIP_MESSAGE_DELETE: 'Cancella messaggio',
  Paused: 'Messo in pausa',
  Permissions: 'Permessi',
  operator_name: 'Nome',
  open_conversations: 'Conversazioni aperte',
  no_open_conversations: 'Nessuna conversazione aperta / in coda in attesa',
  logged_in_operators: 'Operatori in chat',
  language: 'Langua',
  cnt_pokes: '# pokes',
  cnt_messages: '# messagfi',
  cnt_operators: '# operatori',

  oldest_message: 'Messaggio più vecchio',
  newest_message: 'Messaggio più recente',
  QUEUE_STATS: 'Statistiche coda',
  SOMEONE_ELSE_ANSWERING: '{name} sta rispondendo in questa chat.',
  SAVE: 'Salva',
  SET_ALERT: 'Imposta alert',
  AT_CUSTOMER_PROFILE: 'Al profilo cliente',
  STOP_POKES: 'Stop pokes',
  IGNORE: 'Ignora chat',
  UNTIL_NEXT_MESSAGE: 'Fino al prossimo messaggio',
  IGNORE_CONVERSATION_WARNING:
    "L'utente non riceverà alcuna risposta! Utilizzarlo solo in caso di abuso e nessuno dovrebbe rispondere (ad esempio i minori).",
  stats: {
    total: 'Totale di tutti gli operatori',
    message_type: 'Tipo di messaggio',
    count_messages: '# messaggi',
    count_messages_answered: '# messaggi con risposta',
    count_messages_answered_percentage: 'Percentuale con risposta',
    types: {
      starter: 'Avvio di conversazione',
      reply: 'Prima risposta al messaggio dei clienti (o dopo la foto)',
      extra_reply: 'risposte aggiuntive',
      poke: 'Poke',
      extra_poke: 'Poke aggiuntivo',
      starter_photo: 'Avvio di conversazione (foto)',
      reply_photo: 'Prima risposta al messaggio dei clienti (foto)',
      extra_reply_photo: 'Ulteriori risposte (foto)',
      poke_photo: 'Poke (foto)',
      extra_poke_photo: 'Poke aggiuntivo (foto)',
      starter_gift: 'Avvio di conversazione (regalo)',
      reply_gift: 'Prima risposta al messaggio dei clienti (regalo)',
      extra_reply_gift: 'Risposte aggiuntive (regalo)',
      poke_gift: 'Poke (regalo)',
      extra_poke_gift: 'Poke aggiuntivo (regalo)',
    },
  },

  Stats: 'Statistiche',
  OTHER_CHAT_COUNT: '1 chat | {count} chats',
  FREE_MEMBER: 'Utente in prova',
  PREMIUM_MEMBER: 'Utente Premium',
  USER_CREDITS: '1 credit rimanente | {count} crediti rimanenti',
  LAST_MESSAGE: '1 messaggio, {date} | {num_messages} messagegi, ultimo {date}',
  MEMBER_SINCE: 'Membro dal {date}',
  MESSAGE_COUNT: '1 messaggio | {count} messages',
  PHOTOMESSAGE_COUNT: '1 foto | {count} photos',
  INVISIBLE_PHOTOMESSAGE_COUNT: '1 foto invisibile | {count} foto invisibili',
  MESSAGE_SINCE: 'Primo messaggio: {date}',
  today: 'Oggi',
  yesterday: 'Ieri',
  TFA_LOGIN:
    'Inserisci le 6 cifre che vedi nella tua app 2FA (Google Authenticater o Authy)',
  SEND_POKE: 'Manda a {name} un poke!',
  AGE_DISPLAY: '{age} anni',
  'Empty chat, send a message first.':
    'Chat vuota, invia un messaggio per primo.',
  'Load more messages': 'Carica più messaggi',
  loading_messages: 'I messaggi si stanno caricando...',
  SEND_MESSAGE_PLACEHOLDER: 'Invia messaggio',
  SEND_BTN: 'Invia',
  LOCKED_BY: 'Messaggio bloccato da: {name}',
  CUSTOMER: 'CLIENTE',
  PLAYER: 'PLAYER',
  'Open chat queue': 'Apri coda chat',
  'Open poke queue': 'Apri coda poke',
  'Poke Queue': 'Coda Poke',
  Logout: 'Logout',
  'Waiting for new message': 'In attesa di un nuovo messaggio',
  'Message Queue': 'Messaggi in coda',
  LOGIN_BLOCKED: 'Troppi tentativi falliti, riprova tra 10 minuti.',
  LOGIN_FAILED: 'Login fallito, prova ancora',
  male: 'maschio',
  female: 'femmina',
  personal: 'Informazioni personali',
  name: 'Nome',
  age: 'Età',
  city: 'Residenza',
  birthday: 'Compleanno',
  sports: 'Sport',
  job: 'Lavoro',
  likes: 'Likes',
  hobbies: 'Hobbies',
  children: 'Figli',
  pets: 'Animali',
  important: 'Importante',
  piercings: 'Piercings',
  reason: 'Motivo',
  other: 'Altro',
  smokes: 'Fumatore',
  tattoos: 'Tattoos',
  eye_color: 'Colore degli occhi',
  hair_color: 'Colore dei capelli',
  reletionship_status: 'Relazioni',
  yes: 'Si',
  no: 'No',
  blue: 'Blu',
  brown: 'Marrone',
  green: 'Verde',
  gray: 'Grigio',
  hazel: 'Nocciola',
  bald: 'Calvo',
  black: 'Neri',
  blond: 'Biondi',
  'dark blond': 'biondo scuro',
  'dark brown': 'Marrone scuro',
  red: 'Rossi',
  divorced: 'Divorziato',
  'living together': 'Convivenza',
  married: 'Sposato',
  single: 'Single',
  unmarried: 'Mai sposato',
  widowed: 'Vedovo',
  sometimes: 'A volte',
  stopping: 'Sto cercando di smettere',
  hetero: 'Etero',
  gay: 'Gay',
  lesbian: 'Lesbica',
  bi: 'Bi-sex',
  length: '@:(Length)',
  weight: 'Peso',
};
