export default function ({ $auth }) {
  $auth.onRedirect((to, from) => {
    if ($auth.loggedIn) {
      $auth.$storage.setUniversal('tfa-redirect', to);

      return '/tfa';
    }
    // you can optionally change `to` by returning a new value
  });
}
